<template>
   <div id="app">
    <timeline ref="timeline" :items="items" :groups="groups" :options="options">
    </timeline>
  </div>
 </template>
 
 <script>
 import { mapGetters, mapActions } from "vuex";
 
 export default {
     data () {
     return {
       groups: [
         {
           id: 11,
           content: 'Research'
         },
         {
           id: 2,
           content: 'Research'
         }
       ],
       items: [
         {
           id: 11,
           group: 11,
           start: new Date(),
           content: ''
         },
         {
           id: 2,
           group: 2,
           start: 'Wed Feb 02 2022 04:42:12 GMT+0500 (Uzbekistan Standard Time)',
           end: 'Wed Feb 03 2022 04:22:12 GMT+0500 (Uzbekistan Standard Time)',
           content: '<div class="vis__content" style="transform: translateX(0px);"><div class="vis__into vis__blue1"><div class="vis__primary vis__blue" style="width:20%"></div><div class="vis__index"><div class="vis__nowrap"><div class="vis__image"><img src="https://preview.keenthemes.com/good/assets/media/avatars/300-2.jpg" alt=""></div><div class="vis__second"><img src="https://preview.keenthemes.com/good/assets/media/avatars/300-2.jpg" alt=""></div></div><a href="#" class="vis__text">Meeting</a></div><div class="vis__other">20%</div></div></div>'
         }
       ],
       options: {
         editable: false,
         width: '100%',
         height: '85vh',
        //  maxMinorChars: 10,
         zoomFriction: 10,
         zoomMax: 1000*60*60*24*365,
         zoomMin: 1000*60*60*24*32,
        //  end: new Date(),
         start: new Date(),
         margin: {
           item: 50
         },
        
         horizontalScroll: true,
         verticalScroll: true,
         zoomable: true,
         showMajorLabels:true,
       },
       activeItem: 'week'
     }
   },
   computed: {
     ...mapGetters({
       mode: "MODE",
       timeLineTasks: 'home/timeLineTasks',
       timeLineTaskGroups: 'home/timeLineTaskGroups'
     }),
     kecha(){
      let date = new Date();
      date.setDate(date.getDate() - 1);
      return date;
     }
   },
   created(){
     this.taskTimeLine().then(res => {
       this.groups = JSON.parse(JSON.stringify(this.timeLineTaskGroups));
       this.items = JSON.parse(JSON.stringify(this.timeLineTasks)); 
       this.items = this.items.map(item => {
         return {id: item.id, group: item.group ,start: new Date(item.start), end: new Date(item.end), content: item.content};
       });
     })
   },
   methods: {
     ...mapActions({
       taskTimeLine: 'home/taskTimeLine'
     }),
 
 
   }
 }
 </script>
 
 <style>
 
 </style>
 