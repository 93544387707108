<template>
    <div>
      <div class="app-modal__header p-5 d-flex f-between w-100">
          <p class="large--title m-0">
              <span class="mr-5">
                {{ $t('message.filter') }}
              </span>
          </p>
          <div class="mobil">
            <!-- <el-button plain type="success" size="medium" class="mr-1" :loading="waiting" @click="submit(false)"> {{ $t('message.save') }}</el-button> -->
            <el-button   type="info" size="medium" @click="close()" icon="el-icon-close" > {{ $t('message.close') }}</el-button>
          </div>
      </div>
      <div class="content-in__title filter-title w-100">
          <div class="step-block-deals-from w-100">
            <div class="block-sarche">
              <el-form ref="form" :model="filterForm" >
                    <el-form-item class="mb-3 w-100" v-if="authUser.is_super_admin">
                        <select-user
                            :size="'medium'"
                            :placeholder="$t('message.user')"
                            :id="filterForm.user_id"
                            :class="mode ? 'input__day' : 'input__night'"
                            :task_users="true"
                            v-model="filterForm.user_id"
                        >
                        </select-user>
                    </el-form-item>

                    <el-form-item class="mb-3 w-100">
                        <select-filial
                            :size="'medium'"
                            :placeholder="$t('message.filial')"
                            :id="filterForm.filial_id"
                            v-model="filterForm.filial_id"
                            :class="mode ? 'input__day' : 'input__night'"
                        >
                        </select-filial>
                    </el-form-item>

                    <el-form-item class="mb-3 w-100">
                        <select-filter
                            :size="'medium'"
                            :placeholder="$t('message.filter')"
                            :id="filterForm.label_ids"
                            :class="mode ? 'input__day' : 'input__night'"
                            v-model="filterForm.label_ids"
                        >
                        </select-filter>
                    </el-form-item>
                    <el-form-item class="mb-3 w-100">
                        <select-priority
                            :size="'medium'"
                            :placeholder="$t('message.priority')"
                            :id="filterForm.priority"
                            v-model="filterForm.priority"
                        >
                        </select-priority>
                    </el-form-item>

                    <el-form-item class="mb-3 w-100">
                        <el-select class="w-100" multiple v-model="filterForm.region"   :size="'medium'" placeholder="Me">
                            <el-option label="Executive" value="q">
                                <span style="float: left">Executive</span>
                                <span style="float: right; font-size: 13px">
                                    <i class="el-icon-check"></i>
                                </span>
                            </el-option>
                            <el-option label="Author" value="beijing">
                                <span style="float: left">Author</span>
                                <span style="float: right; font-size: 13px">
                                    <i class="el-icon-user"></i>
                                </span>
                            </el-option>
                            <el-option label="Subscriber" value="beijing1">
                                <span style="float: left">Subscriber</span>
                                <span style="float: right; font-size: 13px">
                                    <i class="el-icon-bell"></i>
                                </span>
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-3 w-100">
                        <el-select class="w-100" multiple v-model="filterForm.region"   :size="'medium'" placeholder="Labels">
                            <el-option label="Executive" value="shanghai2">
                                <span style="float: left">Веха проекта</span>
                                <span style="float: right; font-size: 13px">
                                    <i class="el-icon-top"></i>
                                </span>
                            </el-option>
                            <el-option label="Author" value="beijing">
                                <span style="float: left">Застрявший!</span>
                                <span style="float: right; font-size: 13px">
                                    <i class="el-icon-loading"></i>
                                </span>
                            </el-option>
                            <el-option label="Subscriber" value="beijing1">
                                <span style="float: left">Требуется одобрение</span>
                                <span style="float: right; font-size: 13px">
                                    <i class="el-icon-check"></i>
                                </span>
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-3 w-100">
                        <el-select class="w-100" multiple v-model="filterForm.region"   :size="'medium'" placeholder="Terms">
                            <el-option-group>
                                <el-option label="overdue" value="shanghai2">
                                    <span style="float: left">overdue</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="el-icon-minus"></i>
                                    </span>
                                </el-option>
                                <el-option label="deadline" value="shanghai2">
                                    <span style="float: left">close to deadline</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="el-icon-timer"></i>
                                    </span>
                                </el-option>
                            </el-option-group>
                            <el-option-group>
                                <el-option label="today" value="shanghai2">
                                    <span style="float: left">today (0)</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="el-icon-collection-tag"></i>
                                    </span>
                                </el-option>
                                <el-option label="tomorrow" value="beijing">
                                    <span style="float: left">tomorrow! (0)</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="el-icon-date"></i>
                                    </span>
                                </el-option>
                                <el-option label="week" value="beijing1">
                                    <span style="float: left">this week</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="el-icon-c-scale-to-original"></i>
                                    </span>
                                </el-option>
                                <el-option label="month" value="beijing1">
                                    <span style="float: left">this month</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="el-icon-date"></i>
                                    </span>
                                </el-option>
                            </el-option-group>
                            <el-option-group>
                                <el-option label="already" value="shanghai2q">
                                    <span style="float: left">already started</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="el-icon-finished"></i>
                                    </span>
                                </el-option>
                                <el-option label="start" value="beijingw">
                                    <span style="float: left">awaiting start</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="fa-solid fa-play"></i>
                                    </span>
                                </el-option>
                                <el-option label="dates" value="beijing1w">
                                    <span style="float: left">no due dates</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="fa-brands fa-nfc-symbol"></i>
                                    </span>
                                </el-option>
                                <el-option label="Subscriber" value="beijing1">
                                    <span style="float: left">this month</span>
                                    <span style="float: right; font-size: 13px">
                                        <i class="fa-regular fa-calendar"></i>
                                    </span>
                                </el-option>
                            </el-option-group>

                        </el-select>
                    </el-form-item>

              </el-form>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex";
import selectUser from "@/components/inventory/select-deal-users";
import selectFilial from "@/components/inventory/select-filial";
import selectFilter from "@/components/inventory/select-filter";
import selectPriority from "@/components/inventory/select-priority";

export default {
    name: 'GoCrmFrontedFilter',
    components: {
        selectUser,
        selectFilial,
        selectFilter,
        selectPriority
    },
    data() {
        return {
          filterForm: {},
          waiting: false
        };
    },

    mounted() {

    },
    watch: {
      filterForm: {
          handler: async function(newVal, oldVal) {
            this.$emit('input', this.filterForm)

          },
          deep: true,
          immediate: true
      },
    },

    methods: {
      submit() {
        this.$emit('input', this.filterForm)
        this.close()
      },
      close(){
        this.$parent.$parent.filterDrawerVisible = false;
      }

    },
    computed: {
      ...mapGetters({
          authUser: "auth/user",
          mode: "MODE"
      }),
    }
};
</script>

<style lang="scss" scoped>

@media only screen and (max-width: 1566px){
  .input__day {
      width: 100% !important;
  }
}
</style>
