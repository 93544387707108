<template>
  <div
    class="card-table mb-0 border-none pch-M height-M92"
    v-loading="loadingData"

  >
    <div class="deal-task-tracker tasks--tracker">
      <div class="card-table-body ifon-add-title-block pb-2 pl-0 mb-0">
        <div class="header__flex">
          <div class="content-in__title filter-title">
            <h1
              class="content-title m-0  ml-2"
              :class="mode ? 'content__titleday' : 'content__titlenight'"
            >
              {{ $t("message.task") }}
              <i
                @click="refresh()"
                class="el-icon-refresh cursor-pointer ml-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              ></i>
            </h1>
          </div>
          <div class="content__setting mb-1">
            <div class="tabs-task">
              <a
                v-on:click="activetab = '1'"
                v-bind:class="[activetab === '1' ? 'active' : '']"
                ><i class="fa-solid fa-table-columns"></i
              ></a>
              <a
                v-on:click="activetab = '2'"
                v-bind:class="[activetab === '2' ? 'active' : '']"
                >
                <i class="fa-solid fa-list-check"></i>
                </a>
              <a
                v-on:click="activetab = '3'"
                v-bind:class="[activetab === '3' ? 'active' : '']"
                >
                <i class="fa-solid fa-bars-progress"></i>
                </a>
            </div>

            <el-button
              @click="filterDrawerVisible = true"
              type="primary"
              size="small"
              class="standard-btn"

            >
              <i class="fa-solid fa-filter mr-0"></i>
            </el-button>
            <el-button
              @click="createNewBoard = true"
              type="primary"
              icon="el-icon-s-order"
              size="small"
              class="standard-btn"
            >
              {{ $t("message.create_board") }}
            </el-button>
          </div>
        </div>
      </div>

      <div class="content">
        <div v-if="activetab === '1'" class="tabcontent">
          <div class="tasks__relative">
            <div class="tasks__absolute">
              <div
                class="deal-track-wrap"
                :class="mode ? 'scrollx__day' : 'scrollx__night'"
              >
                <draggable
                  v-model="rows"
                  @change="changeBoardIndex"
                  class="d-flex mobil-Scroll"
                  :disabled="dragOptionsMobil2"
                >
                  <div
                    class="deal-track-items rounded hidden"
                    v-for="row in rows"
                    :key="row.index"
                    :class="{ minBlock: checked.some((id) => id == row.id) }"
                  >
                    <div
                      class="deal-track-header content-title content-title--medium p-3 d-flex f-between align-center"
                      :style="{ 'border-color': row.color }"
                    >
                      <div>
                        <label
                          class="tag checked-click"
                          :style="{ background: row.color }"
                          :for="row.id"
                        >
                         <i class="fa-regular fa-eye eye-open"></i>
                         <i class="fa-regular fa-eye-slash eye-off"></i>
                          <input
                            v-model="checked"
                            type="checkbox"
                            name="a"
                            :id="row.id"
                            :value="row.id"
                            @change="setToLocalStorage"
                          />
                        </label>
                      </div>
                      <span class="d-block w-100 pointer name-doska">
                        {{ row.name }}
                      </span>
                      <div
                        :class="mode ? 'plus__day' : 'plus__night'"
                        class="add-task mr-2"
                        @click="openDrawer(row.id)"
                        v-if="permissions.some((per) => per.slug == 'tasks.create')"
                      >
                        <!-- <i class="el-icon-circle-plus-outline icon-color"  :style="`--element-color: ${row.color}`"></i> -->
                        <i class="el-icon-plus"></i>
                      </div>
                      <div class="track-header-menu pointer">
                        <el-dropdown trigger="click">
                          <span class="el-dropdown-link">
                            <i class="el-icon-more-outline icon-color tr-r-90" style="font-size: 18px"></i>
                          </span>
                          <el-dropdown-menu
                            slot="dropdown"
                            class="task-track-dropdown"
                          >
                            <el-dropdown-item v-if="authUser.is_super_admin && permissions.some((per) => per.slug == 'taskBoards.update')">
                              <el-button
                                type="text"
                                icon="el-icon-edit"
                                size="medium"
                                @click="editTaskTitle(row)"
                                >
                                {{ $t("message.update") }}
                              </el-button>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="authUser.is_super_admin && permissions.some((per) => per.slug == 'taskBoards.delete')">
                              <el-button
                                type="text"
                                icon="el-icon-delete"
                                size="medium"
                                @click="destroy(row)"
                              >
                                {{ $t("message.delete") }}
                              </el-button>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>


                    <div
                      class="deal-track-body rounded "
                      :class="mode ? 'overflow__day' : 'overflow__night'"
                      >
                      <draggable
                        class="list-group"
                        :list="row.tasks"
                        :group="{ name: 'row' }"
                        @change="changeTaskIndex($event, row)"
                        v-bind="dragOptions"
                        tag="ul"
                        :disabled="dragOptionsMobil2"
                      >
                        <li
                          class="list-group-item"
                          v-for="element in row.tasks"
                          :key="element.id"
                          type="primary"
                        >
                          <div
                            class="control-over"
                            :class="mode ? 'tasks__day' : 'tasks__night'"
                          >
                            <div class="deal-task-items rounded mb-1">
                              <div class="metca"></div>
                              <div
                                v-if="element.cover_image"
                                @click="showImage(element.cover_image)"
                                style="height: 200px"
                              >
                                <el-image
                                  style="
                                    width: 100%;
                                    height: 100%;
                                    overflow: hidden;
                                    border-radius: 10px 10px 0 0;
                                  "
                                  :src="element.cover_image"
                                  fit="cover"
                                >
                                </el-image>
                              </div>

                              <div class="p-0">
                                <div class="top-n-t-d">
                                  <span
                                    class="itme-top-n-t-d nomer-deal-task icon-color"
                                  >
                                    <span>№</span>
                                    {{ element.id }}
                                  </span>
                                  <span class="itme-top-n-t-d">
                                    <i
                                      class="fa-regular fa-handshake mr-1 icon-color"
                                    ></i>
                                    <span class="icon-color">{{
                                      element.deal_id
                                    }}</span>
                                  </span>
                                  <span class="itme-top-n-t-d check-task">
                                    <i class="fa-regular fa-square-check mr-1 icon-color"> </i>
                                    <span class="icon-color">
                                      {{ element.checkedMiniTasks }}/{{ element.allMiniTasks }}
                                    </span>
                                  </span>
                                  <span class="itme-top-n-t-d flex_i-none icon-color">
                                    <el-dropdown trigger="click" class="ml-2" @command="handleTaskCommand">
                                      <span class="el-dropdown-link">
                                        <i class="fa-solid fa-bars-staggered icon-color"></i>
                                      </span>
                                      <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="{action: 'showDialog', task_id: element.id}">
                                          <span @click="showDialog(element.id)">{{$t('message.calendarNotification')}}</span>
                                        </el-dropdown-item>

                                        <el-dropdown-item :command="{action: 'deleteTaskParent', task_id: element.id}" v-if="permissions.some((per) => per.slug == 'tasks.delete')">
                                          <span  @click="deleteTaskParent(element.id)" >
                                            {{ $t('message.delete_task') }}
                                          </span>
                                        </el-dropdown-item>
                                      </el-dropdown-menu>
												            </el-dropdown>
                                    <!-- <i
                                      class="fa-solid fa-bars-staggered icon-color"
                                      @click="showDialog(element.id)"
                                    ></i> -->
                                  </span>
                                </div>

                                <div class="p-2 pb-1"  @[dynamicEvent]="openTaskUpdate(element)">
                                  <div class="name-tesk mb-1">
                                    {{ element.name }}
                                  </div>

                                  <div class="d-flex f-between">
                                    <div class="users__info h-29">
                                      <div class="task_user_give">
                                        <div class="itme-userss">
                                          <el-popover
                                            :open-delay="popoverShowTime"
                                            placement="top"
                                            popper-class="p-0 myir"
                                            width="250"
                                            trigger="hover"
                                          >
                                            <div class="click-block">
                                              <div class="top-u-click">
                                                <img
                                                  class="task_user_give_img"
                                                  :src="element.user ?
                                                    element.user.avatar
                                                      ? element.user.avatar
                                                      : '/img/flag/employee.svg'
													  : '/img/flag/employee.svg'
                                                  "
                                                  alt=""
                                                />
                                                <div class="tt-name">
                                                  <div>
                                                    {{ element.user ? element.user.name  : ''}}
                                                  </div>
                                                  <span
                                                    ><i
                                                      class="el-icon-warning-outline"
                                                    ></i>
                                                    ID: {{ element.user ? element.user.id : ''}}
                                                  </span>
                                                  <span>
                                                    <i
                                                      class="el-icon-mobile-phone"
                                                    ></i>
                                                    Tel:
                                                    {{ element.user ? element.user.phone : ''}}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>

                                            <el-button slot="reference">
                                              <div class="task_user_give_names">
                                                <img
                                                  class="task_user_give_img"
                                                  :src=" element.user ?
                                                    element.user.avatar
                                                      ? element.user.avatar
                                                      : '/img/flag/employee.svg'
													  : '/img/flag/employee.svg'
                                                  "
                                                  alt=""
                                                />
                                              </div>
                                            </el-button>
                                          </el-popover>
                                        </div>
                                      </div>
                                      <i
                                        :class="
                                          mode ? 'text__day' : 'text__night1'
                                        "
                                        class="el-icon-arrow-right ml-1 mr-1"
                                        v-if="
                                          element.participants &&
                                          element.participants.length > 0
                                        "
                                      ></i>
                                      <div class="d-flex task_user_accept">
                                        <div class="itme-userss ml-2 d-h-29">
                                          <el-popover
                                            :open-delay="popoverShowTime"
                                            class="user-img-task"
                                            placement="top"
                                            popper-class="p-0 myir"
                                            width="250"
                                            v-for="participant in element.participants"
                                            :key="participant.id"
                                            trigger="hover"
                                          >
                                            <div class="click-block">
                                              <div class="top-u-click">
                                                <img
                                                  class="task_user_give_img"
                                                  :src="
                                                    participant.avatar
                                                      ? participant.avatar
                                                      : '/img/flag/employee.svg'
                                                  "
                                                  alt=""
                                                />
                                                <div class="tt-name">
                                                  <div>
                                                    {{ participant.name }}
                                                  </div>
                                                  <!-- <span>{{ participant.email ? participant.email : ''}}</span> -->
                                                  <span
                                                    ><i
                                                      class="el-icon-warning-outline"
                                                    ></i
                                                    >ID:
                                                    {{ participant.id }}</span
                                                  >
                                                  <span
                                                    ><i
                                                      class="el-icon-mobile-phone"
                                                    ></i
                                                    >Tel:
                                                    {{
                                                      participant.phone
                                                    }}</span
                                                  >
                                                </div>
                                              </div>
                                            </div>

                                            <el-button slot="reference">
                                              <img
                                                class="task_user_give_img"
                                                :src="
                                                  participant.avatar
                                                    ? participant.avatar
                                                    : '/img/flag/employee.svg'
                                                "
                                                alt=""
                                              />
                                            </el-button>
                                          </el-popover>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="task-term proritet-date pl-0" >
                                    <span
                                     v-if="element.begin_date && element.end_date.length > 0"
                                      class="data-deal-task-end ml-0"
                                      :class="
                                        dayDiff(element) >= 0
                                          ? 'coloa2'
                                          : 'coloa3'
                                      "
                                    >
                                      <i class="el-icon-time"></i>
                                      {{ formatDate(element.begin_date) }}
                                      <i class="el-icon-arrow-right"></i>
                                      {{ formatDate(element.end_date) }}
                                      <i class="el-icon-arrow-right"></i>
                                      {{ dayDiff(element) }}
                                    </span>
                                    <span class="proritet" :class="'prior'+element.priority">{{ element.priority }}</span>
                                  </div>

                                  <div class="metka-r p-0 pt-2">
                                    <span
                                      :class="'color_' + label.color_id"
                                      v-for="label in element.labels"
                                      :key="label.id"
                                    >
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </draggable>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- end tabcontent -->

        <div v-if="activetab === '2'" class="tabcontent">
          <div class="p-3">
            <draggable
              v-model="rows"
              @change="changeBoardIndex"
              :sort="dragOptionsMobil"
            >
              <el-collapse v-model="activeNames1" class="mb-4 w-100">
                <el-collapse-item name="1">
                  <template slot="title">
                    <div
                      class="task-bg-borde-co"
                      style="border-color: red"
                    ></div>
                    <div
                      class="name-dosca"
                      :class="mode ? 'text__day' : 'text__white'"
                    >
                      Go-Med
                    </div>
                  </template>

                  <div class="tabel-collapse">
                    <table
                      class="tabel-collapse-style"
                      :class="mode ? 'text__day' : 'text__white'"
                    >
                      <thead>
                        <tr>
                          <th class="tw-5">№</th>
                          <th>Task</th>
                          <th class="tw-15">Assignees</th>
                          <th class="tw-5">Task</th>
                          <th class="tw-10">Deals</th>
                          <th class="tw-10">Progres</th>
                          <th class="tw-10">Stage</th>
                          <th class="tw-15">Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><b>13</b></td>
                          <td>
                            <div class="name-link-task">Front End Task</div>
                          </td>
                          <td>
                            <div class="d-flex f-between mb-3">
                              <div class="users__info h-29">
                                <div class="task_user_give">
                                  <div class="itme-userss">
                                    <el-popover
                                      :open-delay="popoverShowTime"
                                      class="user-img-task"
                                      placement="top"
                                      popper-class="p-0 myir"
                                      width="250"
                                      trigger="hover"
                                    >
                                      <div class="click-block">
                                        <div class="top-u-click">
                                          <img
                                            class="task_user_give_img"
                                            src="/img/flag/employee.svg"
                                            alt=""
                                          />
                                          <div class="tt-name">
                                            <div>name</div>
                                            <span
                                              ><i
                                                class="el-icon-warning-outline"
                                              ></i
                                              >ID: 1</span
                                            >
                                            <span
                                              ><i
                                                class="el-icon-mobile-phone"
                                              ></i
                                              >Tel: 999</span
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <el-button slot="reference">
                                        <div class="task_user_give_names">
                                          <img
                                            class="task_user_give_img"
                                            src="/img/flag/employee.svg"
                                            alt=""
                                          />
                                        </div>
                                      </el-button>
                                    </el-popover>
                                  </div>
                                </div>
                                <i
                                  :class="mode ? 'text__day' : 'text__night1'"
                                  class="el-icon-arrow-right ml-1 mr-1"
                                ></i>
                                <div class="d-flex task_user_accept">
                                  <div class="itme-userss ml-2 d-h-29">
                                    <el-popover
                                      :open-delay="popoverShowTime"
                                      class="user-img-task"
                                      placement="top"
                                      popper-class="p-0 myir"
                                      width="250"
                                      trigger="hover"
                                    >
                                      <div class="click-block">
                                        <div class="top-u-click">
                                          <img
                                            class="task_user_give_img"
                                            src="/img/flag/employee.svg"
                                            alt=""
                                          />
                                          <div class="tt-name">
                                            <div>name</div>
                                            <span
                                              ><i
                                                class="el-icon-warning-outline"
                                              ></i
                                              >ID: 1</span
                                            >
                                            <span
                                              ><i
                                                class="el-icon-mobile-phone"
                                              ></i
                                              >Tel: 999</span
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <el-button slot="reference">
                                        <div class="task_user_give_names">
                                          <img
                                            class="task_user_give_img"
                                            src="/img/flag/employee.svg"
                                            alt=""
                                          />
                                        </div>
                                      </el-button>
                                    </el-popover>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span
                              class="check-task"
                              :class="mode ? 'text__day' : 'text__white'"
                            >
                              <i class="fa-regular fa-square-check mr-1"></i>
                              <span :class="mode ? 'text__day' : 'text__white'"
                                >0/1</span
                              >
                            </span>
                          </td>
                          <td>3</td>
                          <td>
                            <el-progress
                              width="36"
                              :stroke-width="3"
                              type="circle"
                              :percentage="25"
                              status="success"
                            ></el-progress>
                          </td>
                          <td>
                            <div class="metka-r">
                              <el-tooltip
                                class="item"
                                effect="dark"
                                content="Top Left prompts info"
                                placement="top-start"
                              >
                                <span class="color_2"></span>
                              </el-tooltip>
                              <el-tooltip
                                class="item"
                                effect="dark"
                                content="Top Left prompts info"
                                placement="top-start"
                              >
                                <span class="color_3"></span>
                              </el-tooltip>
                              <el-tooltip
                                class="item"
                                effect="dark"
                                content="Top Left prompts info"
                                placement="top-start"
                              >
                                <span class="color_5"></span>
                              </el-tooltip>
                              <el-tooltip
                                class="item"
                                effect="dark"
                                content="Top Left prompts info"
                                placement="top-start"
                              >
                                <span class="color_4"></span>
                              </el-tooltip>
                            </div>
                          </td>
                          <td>
                            <div class="task-term">
                              <span class="data-deal-task-end ml-0 coloa3">
                                <i class="el-icon-time"></i>
                                2022-04-01
                                <i class="el-icon-arrow-right"></i>
                                2022-04-01
                                <i class="el-icon-arrow-right"></i>
                                2
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="more">
                      <span> More </span>
                    </div>
                    <div
                      class="t-add-task-n"
                      :class="mode ? 'text__day' : 'text__white'"
                    >
                      <i class="el-icon-plus"></i>
                      Add a task
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </draggable>
          </div>
        </div>
        <!-- end tabcontent -->

			<div v-if="activetab === '3'" class="tabcontent">
				<timelineblock />
			</div>
      </div>

			<!-- AppModal  Dialog -->
			<div class="app-modal app-modal__full">
				<el-dialog

				v-if="selectedImageURL"
				:title="$t('message.image')"
				:append-to-body="true"
				custom-class="mm-picture-show"
				:visible.sync="dialogPictureVisible"
				>
				<div class="text-center w-100">
						<el-image
						style="object-fit: contain"
						class="p-1"
						:src="selectedImageURL"
						fit="fill"
						>
						</el-image>
				</div>
				</el-dialog>

				<el-dialog
				class="modal-style-m "
				:visible.sync="appModal"
				ref="drawerCreate"
				:close-on-press-escape="false"
				:close-on-click-modal="false"
				@opened="drawerOpened('drawerCreateChild')"
				@closed="drawerClosed('drawerCreateChild')"
				:fullscreen="true"
				>

				<AppModal
						:board_id="board_id"
						ref="drawerCreateChild"
						drawer="drawerCreate"
				/>
				</el-dialog>

				<el-dialog
					class="modal-style-m tash-select"
					:visible.sync="taskUpdateDrawer"
					ref="drawerUpdate"
					:close-on-press-escape="false"
					:close-on-click-modal="false"
					@opened="drawerOpened('drawerUpdateChild')"
					@closed="drawerClosed('drawerUpdateChild')"
					:fullscreen="true"
				>
				<AppModalUpdate
						:selectedItem="selectedItem"
						ref="drawerUpdateChild"
						drawer="drawerUpdate"
				/>
				</el-dialog>
			</div>
			<!-- End AppModal  Dialog -->

			<!-- Create New Task Dialog -->
			<el-dialog
				class="create-task-modal el-dialog-w-100 "
				:title="form.id ? $t('message.update_board') : $t('message.create_board')"
				:visible.sync="createNewBoard"
				width="30%"
					@closed="closeTaskBoardDialog"
			>
				<div class="task-form">
						<el-form ref="form" :model="form" :rules="rules" label-position="top">
							<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item
								:label="$t('message.name')"
								class="label_mini"
								prop="name"
								>
								<el-input
										:placeholder="$t('message.name')"
										v-model="form.name"
										size="medium"
										autocomplete="off"
								></el-input>
								</el-form-item>
							</el-col>

							<el-col :span="24">
								<el-form-item
								:label="$t('message.color')"
								class="label_mini"
								prop="color"
								>
								<div class="color-picker-wrap d-flex">
										<el-color-picker
										v-model="form.color"
										@active-change="changeColor"
										></el-color-picker>
										<div class="picker-box ml-4 rounded w-100 p-1">
										<div
											class="h-100 rounded-sm"
											:style="{ background: form.color }"
										></div>
										</div>
								</div>
								</el-form-item>
							</el-col>

							<el-col :span="24">
								<el-form-item
									:label="$t('message.filials')"
									class="label_mini"
									prop="filial_id"
								>
									<select-filial
										:size="'medium'"
										:placeholder="$t('message.filial')"
										:id="form.filial_id"
										v-model="form.filial_id"
										:project_id.sync="project_id"
									>
									</select-filial>
								</el-form-item>
							</el-col>

										<el-col :span="24" v-show="form.id">
								<el-form-item class="label_mini mb-1" prop="filial_id">
								<select-projects
										:size="'medium'"
										:id="form.project_id"
										v-model="form.project_id"
								></select-projects>
								</el-form-item>
							</el-col>
							</el-row>
						</el-form>
						<el-button
							class="mt-4"
							type="primary"
							:icon="form.id ? 'el-icon-edit' : 'el-icon-circle-plus-outline'"
							size="medium"
							@click="submit(true)"
							>{{ form.id ? $t("message.update") : $t("message.create") }}
						</el-button>
				</div>
			</el-dialog>
			<!-- End Create New Task Dialog -->
			
			<!-- Notification Dialog -->
			<el-dialog
				:title="$t('message.notifications_nn')"
				:visible.sync="dialogVisible"
				width="30%"
				@open="openDialog"
				class="el-dialog-w-100"
			>
				<el-form
					ref="notification_form"
					:model="notification_form"
					:rules="rules"
					label-position="top"
				>
					<el-form-item :label="$t('message.notificationTypes')" prop="type_id">
							<select-notification-types
								v-model="notification_form.type_id"
								:id="notification_form.type_id"
							>
							</select-notification-types>
					</el-form-item>
					<el-form-item :label="$t('message.text')" prop="text">
							<el-input
								type="textarea"
								v-model="notification_form.text"
							>
							</el-input>
					</el-form-item>
					<el-form-item :label="$t('message.samples_for_messages')">
						<el-select 
							v-model="notification_form.sampler_text" 
							@change="setMessageText" 
							:placeholder="$t('message.samples_for_messages')"
							class="w-100"
							size="medium"
						>
							<el-option
								v-for="message in last_messages"
								:key="'message-' + message.id"
								:label="message.text"
								:value="message.text"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('message.activity_time')">
						<el-col :span="11">
							<el-date-picker
								type="datetime"
								:placeholder="$t('message.activity_time')"
								v-model="notification_form.activity_time"
								style="width: 100%"
								format="yyyy-MM-dd HH:mm"
								value-format="yyyy-MM-dd HH:mm"
							>
							</el-date-picker>
						</el-col>
					</el-form-item>
					<el-form-item :label="$t('message.users')" prop="user_ids">
						<el-select
							v-model="notification_form.user_ids"
							multiple
							collapse-tags
							filterable
							:placeholder="$t('message.users')"
							:loading="notification_loading"
							class="w-100"
						>
							<el-option
								v-for="item in usersList"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-row>
						<el-button
							@click="saveSysteamNotification"
							:loading="loadingButton"
							type="success"
						>
							{{ $t("message.save") }}
						</el-button>
						<el-button @click="dialogVisible = false" type="danger">
							{{ $t("message.close") }}
						</el-button>
					</el-row>
				</el-form>
			</el-dialog>
			<!-- End Notification Dialog -->
				
			<!-- MyFilter Drawer -->
			<el-drawer
				:with-header="false"
				:class="mode ? 'l-modal-style' : 'd-modal-style'"
				class="create-task-modal bg-se"
				:visible.sync="filterDrawerVisible"
				:append-to-body="true"
				size="50%"
			>
				<div>
					<Myfilter v-model="filterForm"></Myfilter>
				</div>
			</el-drawer>
			<!-- End MyFilter Drawer -->

			<!-- Delete Task Dialog -->
			<el-dialog
				class="KP_modal el-dialog-w-100"
				:title="$t('message.delete')"
				:visible.sync="drawerDeleteTask"
				:append-to-body="true"
				width="30%"
				center
				ref="drawerDeleteTask"
				@opened="drawerOpened('deleteTaskComponent')"
				@closed="drawerClosed('deleteTaskComponent')"
			>

				<delete-task
				ref="TaskOfferComponent"
				:task_id="task_id"
				@deleteTaskClose="deleteTaskClose"
				drawer="drawerTaskOfferCreate"
				>
				</delete-task>
			</el-dialog>
			<!-- End Delete Task Dialog -->
      </div>
   </div>
</template>

<script>
import draggable from "vuedraggable";
import AppModal from "./components/add-modal";
import AppModalUpdate from "./components/add-modal-update";
import { mapGetters, mapActions, mapState } from "vuex";
import list from "@/utils/mixins/list";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import selectUser from "@/components/inventory/select-deal-users";
import selectFilial from "@/components/inventory/select-filial";
import selectFilter from "@/components/inventory/select-filter";
import Myfilter from "./components/my-filter";
import Timelineblock from "./timelineblock.vue";
import SelectNotificationTypes from "./../../components/inventory/select-notification-types";
import selectProjects from '@/components/inventory/select-projects';
import deleteTask from './components/delete-task';

export default {
  name: "tasks",
  mixins: [list, form, drawer],
  props: {
	project_id: {
		default: null
	}
  },
  components: {
    draggable,
    AppModal,
    AppModalUpdate,
    selectUser,
    selectFilial,
    selectFilter,
    Myfilter,
    Timelineblock,
    SelectNotificationTypes,
	 selectProjects,
	 deleteTask
  },
  data() {
    return {
      popoverShowTime: 500,
      checked: [],
      transferTask: "",
      selectedImageURL: "",
      dialogPictureVisible: false,
      board_id: null,
      appModal: false,
      titleChangedRow: null,
      colorPicker: "#409EFF",
      taskName: "",
      newTaskTitle: "",
      createNewBoard: false,
      editTaskTitleModal: false,
      taskUpdateDrawer: false,
      rows: [],
      color: "#409EFF",
      dynamicEvent: "dblclick",
      dragOptionsMobil: false,
      dragOptionsMobil2: false,
      filterDrawerVisible: false,
      activetab: "1",
      activeNames: true,
      activeNames1: ["1"],
      activeNames2: ["1"],
      usersList: [],
      notification_loading: false,
      dialogVisible: false,
		drawerDeleteTask: false,
		task_id: null,
		notification_form: {},
	   last_messages: []
    };
  },

  watch: {
    list: {
      handler: function (newVal, oldVal) {
        this.rows = JSON.parse(JSON.stringify(this.list));
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    ...mapGetters({
      list: "taskBoards/list",
      columns: "taskBoards/columns",
      pagination: "taskBoards/pagination",
      filter: "taskBoards/filter",
      sort: "taskBoards/sort",
      model: "taskBoards/model",
      notification_model: "taskBoards/notification_model",
      rules: "taskBoards/rules",
      permissions: "auth/permissions",
      authUser: "auth/user",
      mode: "MODE",
      users: "users/inventory",
      rules: "systeamNotification/rules",
    }),
  },
  mounted() {
    this.winWidth();
    this.winWidth2();
    this.handleResize();
    const ids = localStorage.getItem("taskId");

    if (ids && ids.length) {
        this.checked = ids.split(',');
    }
  },
  methods: {
	...mapActions({
      save: "taskBoards/store",
      updateTask: "taskBoards/update",
      updateList: "taskBoards/index",
      setPagination: "taskBoards/setPagination",
      updateSort: "taskBoards/updateSort",
      updateFilter: "taskBoards/updateFilter",
      updateColumn: "taskBoards/updateColumn",
      updatePagination: "taskBoards/updatePagination",
      show: "taskBoards/show",
      empty: "taskBoards/empty",
      delete: "taskBoards/destroy",
      refreshData: "taskBoards/refreshData",
      UpdateBoardIndex: "taskBoards/changeIndex",
      UpdateTaskIndex: "tasks/changeIndex",
      showTask: "tasks/show",
      usersInventory: "users/inventoryForFilter",
      saveNotification: "systeamNotification/store",
		getLastMessages: "systeamNotification/getSamplesForMessages"
  }),
  setMessageText(text){
		this.notification_form.text = text;
		this.notification_form.sampler_text = '';
  },
  handleTaskCommand({action, task_id}){
    if(action == 'showDialog'){
      this.showDialog(task_id);
    }else if(action == 'deleteTaskParent'){
      this.deleteTaskParent(task_id);
    }
  },
  setToLocalStorage() {
    localStorage.setItem("taskId", this.checked);
  },
	deleteTaskParent(task_id){
		this.drawerDeleteTask = true;
		this.task_id = task_id;
	},
    winWidth() {
      setInterval(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth >= 1100) {
          this.dragOptionsMobil = true;
        } else {
          this.dragOptionsMobil = false;
        }
      }, 100);
    },
    winWidth2() {
      setInterval(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth >= 1100) {
          this.dragOptionsMobil2 = "";
        } else {
          this.dragOptionsMobil2 = "!enabled";
        }
      }, 100);
    },
    handleResize() {
      setInterval(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1100) {
          this.dynamicEvent = "click";
        } else {
          this.dynamicEvent = "dblclick";
        }
      }, 100);
    },

    colorCheck(percetColor) {
      if (percetColor > 85) {
        return "PrimaryPercet";
      } else if (percetColor > 65) {
        return "SuccessPercet";
      } else if (percetColor > 45) {
        return "WarningPercet";
      } else {
        return "DangerPercet";
      }
    },
    changeTaskIndex: function (evt, row) {
      if (evt.moved || evt.added) {
        let event = "";
        if (evt.moved) {
          event = evt.moved;
        }
        if (evt.added) {
          event = evt.added;
        }

        this.UpdateTaskIndex({
          moved_task_id: event.element.id,
          moved_task_board_id: row.id,
          newIndex: event.newIndex,
        });
      }
    },
    dayDiff(element) {
      let finish_date = new Date(element.finish_date);
      let current_date = new Date();
      let end_date = new Date(element.end_date);
      if (!isNaN(current_date) && !isNaN(end_date)) {
        if (!isNaN(finish_date) && finish_date.getFullYear() !== 1970) {
          let timeDifference = Math.round(
            (end_date.getTime() - finish_date.setHours(0, 0, 0)) /
              (1000 * 3600 * 24)
          );
          return timeDifference;
        } else {
          let timeDifference = Math.round(
            (end_date.getTime() - current_date.setHours(0, 0, 0)) /
              (1000 * 3600 * 24)
          );
          return timeDifference;
        }
      }
    },
    changeBoardIndex(event) {
      if(this.authUser.is_super_admin){
        let old_item = event.moved.newIndex;
        old_item = (event.moved.newIndex > event.moved.oldIndex) ? (old_item - 1) : (old_item + 1);
        this.UpdateBoardIndex({
          id: event.moved.element.id,
          old_id: this.rows[old_item].id,
        });
      }
    },

    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort, project_id: this.project_id };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
				this.notification_form = JSON.parse(JSON.stringify(this.notification_model));
				this.getLastMessages().then((res) => this.last_messages = res.messages);
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    newTaskBoard() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.createNewBoard = true;
    },
	 closeTaskBoardDialog(){
		this.$store.commit('taskBoards/EMPTY_MODEL');
	 },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          if (this.form.id) {
				// this.form.project_id = this.project_id;
            this.updateTask(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
                this.createNewBoard = false;
                this.fetchData();
                this.form = {};
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
              });
          } else {
				this.form.project_id = parseInt(this.project_id);
            this.save(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
                this.createNewBoard = false;
                this.fetchData();
                this.form = {};
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
              });
          }
        }
      });
    },

    openDrawer(board_id) {
      this.board_id = board_id;
      this.appModal = !this.appModal;
    },
    openTaskUpdate(task) {
      this.selectedItem = task;
      this.taskUpdateDrawer = true;
    },
    closeDrawer(drawer) {
      this.appModal = false;
      this.taskUpdateDrawer = false;
      if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
        this.$refs[drawer].closeDrawer();
      }
    },
    destroy(model) {
      this.$confirm(
        this.$t("message.do_you_want_to_delete_board"),
        this.$t("message.warning"),
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.delete(model.id)
          .then((res) => {
            if (res.data.result.success) {
              this.fetchData();
              this.$alert(res);
            } else {
              this.$warning_message(res);
            }
          })
          .catch((err) => {
            this.$alert(err);
          });
      });
    },
    editTaskTitle(row = null) {
      this.createNewBoard = true;
		if (row){
			let data = JSON.parse(JSON.stringify(row));
      	delete data.tasks;
      	this.form = data;
		}
		else this.newTaskBoard();
    },
    changeColor(val) {
      this.form.color = val;
    },
    showImage(url) {
      if (url) {
        if (
          ["image/png", "image/jpeg", "jpeg", "jpg", "png"].includes(
            /[^.]*$/.exec(url)[0]
          )
        ) {
          this.selectedImageURL = url;
          this.dialogPictureVisible = true;
        }
      }
    },
    saveSysteamNotification() {
        if (!this.loadingButton && !this.notification_form.activity_time) {
          this.$message({
            showClose: true,
            message: "must be active time",
            type: "warning",
          });
        } else {
			this.$refs["notification_form"].validate((valid) => {
				if (valid) {
				this.loadingButton = true;
				this.saveNotification(this.notification_form)
					.then((res) => {
					this.loadingButton = false;
					this.$alert(res);
					this.dialogVisible = false;
					})
					.catch((err) => {
					this.$alert(err);
					});
				}
			});
        }
    },
    handleDelete(index, row) {
      this.notification_form.user_ids.splice(
        this.notification_form.user_ids.indexOf(row.id),
        1
      );
    },
    showDialog(deal_id) {
      this.dialogVisible = true;
      this.notification_form.notificationable_id = deal_id;
    },
    openDialog() {
      this.usersInventory({ all_users: "all_users" }).then((res) => {
        this.usersList = JSON.parse(JSON.stringify(this.users));
        this.notification_form.user_ids.push(this.authUser.id);
      });
    },
    searchUsersWithLoad(val) {
      let ids = this.notification_form.user_ids;
      if (val) {
        this.notification_loading = true;
        this.searchUsers(val, ids);
      } else {
        this.notification_loading = true;
        this.usersInventory({ user_ids: ids })
          .then((res) => {
            this.notification_loading = false;
            this.usersList = JSON.parse(JSON.stringify(this.users));
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    searchUsers: _.debounce(function (val, ids) {
      this.usersInventory({ search_val: val, ids: ids })
        .then(() => {
          this.notification_loading = false;
          this.usersList = JSON.parse(JSON.stringify(this.users));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000),
	  deleteTaskClose(val) {
      this.drawerDeleteTask = false;
      if(val){
		  this.listChanged();
      //   this.close();
      }
    },
  },
  	beforeRouteLeave(to, from, next){
		this.$store.commit("taskBoards/EMPTY_LIST");
		this.rows = [];
		this.last_messages = [];
		next();
    }
};
</script>

<style>
.coloa3 {
  background: #ff3f3f;
}
.container__tasks {
  width: 100%;
  margin: 0 !important;
}
.test__width {
  width: 96% !important;
}
.mm-main-index-image-container {
  width: 100%;
  height: 150px;
  background-color: #dee3e7;
  display: inline-block;
}
.add-task i:hover {
  color: var(--element-color);
}
.deal-track-header {
  border-bottom: 1px solid;
}
</style>
